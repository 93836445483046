
import { defineComponent, ref, unref, useContext } from '@nuxtjs/composition-api'
import { newsletterSubscribe } from '~/assets/newsletter'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: undefined },
    title: { type: String, required: true },
    url: { type: String, required: true },
  },
  setup({ url }) {
    const { $config } = useContext()
    const { t_ } = setupTranslationPrefixer('Newsletter')
    const newsletterSubscriptionForm = ref({ emailAdress: '' })
    const handleNewsletterSubscription = async () => {
      const emailAddress = unref(newsletterSubscriptionForm.value).emailAdress
      if (await newsletterSubscribe($config.bridgeSiteUrl, emailAddress)) {
        window.location.href = url
      }
    }

    return { handleNewsletterSubscription, newsletterSubscriptionForm, t_ }
  },
})
